.block-heading
  text-transform: uppercase

  &.center
    text-align: center

  &.blue
    h2, h3, h4
      color: var(--color-blue)

  &.grey
    h2, h3, h4
      color: var(--color-grey)

  @at-root p ~ &
    margin-top: 50px

  div
    font-size: 1.1em
    font-weight: lighter
    line-height: normal
    margin-top: 1em
