.list
  list-style-type: "×"
  margin-left: 12px
  margin-bottom: 10px

  li
    font-weight: lighter
    margin-left: 2px
    padding-left: 10px

.block-shiftedteaser_text ul
  @extend .list

.richtext ul
  @extend .list
