\:root
  --font-size-base: 18px
  --font-size-h1: calc(var(--font-size-base) * 3.556)
  --font-size-h2: calc(var(--font-size-base) * 2.667)
  --font-size-h3: calc(var(--font-size-base) * 1.778)
  --font-size-h4: calc(var(--font-size-base) * 1.333)
  --font-size-h5: calc(var(--font-size-base) * 1.111)
  --font-size-h6: var(--font-size-base)
  --font-size-large: var(--font-size-h4)
  --font-size-small: calc(var(--font-size-base) * 0.778)

.fs
  &-base
    font-size: var(--font-size-base)

  &-h1
    font-size: var(--font-size-h1)

  &-h2
    font-size: var(--font-size-h2)

  &-h3
    font-size: var(--font-size-h3)

  &-h4
    font-size: var(--font-size-h4)

  &-h5
    font-size: var(--font-size-h5)

  &-h6
    font-size: var(--font-size-h6)

  &-large
    font-size: var(--font-size-large)

  &-small
    font-size: var(--font-size-small)

// ==================
// NOTE TO SELF:
// DEFINED AS SUCH HERE
// UNTIL FURTHER NOTICE
// ==================

@media (max-width: 991px)
  .block-hero-text,
  .brand .claim
    font-size: 1.333rem !important

  .block-hero_additional_text
    font-size: 1.1rem !important

  .h1,
  .hero .title,
  h1
    font-size: 2.2rem !important

  .h2,
  h2
    font-size: 2rem !important

  h2.fs-base
    font-size: 1.333rem !important
