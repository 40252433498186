@keyframes blink
  0%
    opacity: 0
  50%
    opacity: 0
  51%
    opacity: 1
  100%
    opacity: 1

// .content
//   &.home
//     padding-top: 60px
//     z-index: -99
//     display: flex
//     width: 100%
//     flex-direction: column
//     justify-content: stretch
//     +from($tablets)
//       height: 80vh

.cover-image
  position: fixed
  width: 100vw
  height: 100vh
  z-index: -99

  img
    position: fixed
    top: 0
    left: 0
    height: 100vh
    z-index: -1
    width: 100%
    object-fit: cover

.brand
  .logotype
    height: auto
    +until($tablets)
      width: 80%
      max-width: 300px
    +from($tablets)
      width: 30vw

  .liftric
    color: var(--color-blue-dark)

  .claim
    font-size: var(--font-size-h3)
    line-height: 1.5
    +from($tablets)
      // margin-left: 10vw
      margin-left: calc(var(--claim-offset) + 1em)
      margin-top: 1em
      max-width: 50vw

  svg
    max-width: 100%

.shell-claims
  width: 99%
  display: flex
  flex-direction: column
  line-height: 1.5
  color: var(--color-grey-medium)
  +until($tablets)
    padding: 5vh 0 15vh
  // margin-left: 20px
  +from($tablets)
    padding: 20vh 0 0
  // margin-left: 120px
  .claims
    display: flex
    line-height: 1.0
    flex-direction: row
    margin-top: 10px
    +until($tablets)
      font-size: var(--font-size-small)

  .shell-claim
    font-size: var(--font-size-h3)
    font-family: var(--font-family-code)
    display: none

  .visible-claim
    display: inline-block
    font-size: var(--font-size-h3)
    font-family: var(--font-family-code)

    &:after
      content: ''
      display: inline-flex
      width: 2px
      height: 18px
      background: var(--color-blue-dark)
      animation: blink 1500ms linear
      animation-iteration-count: infinite
      align-self: center
      margin-left: 1px
      +from($tablets)
        height: 30px
        width: 4px

  .ps1
    font-weight: bold
    font-size: var(--font-size-h3)
    font-family: var(--font-family-code)
    margin-right: 10px
