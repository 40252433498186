#footer
  background-color: var(--color-dark)
  color: white
  padding-top: calc(#{$grid-gutter-width} * 2)
  +from($tablets)
    font-size: var(--font-size-small)
    padding-top: 80px

  [class^="col-"],
  [class*=" col-"]
    +until($tablets)
      margin-bottom: $grid-gutter-width

  p a
    color: currentColor

    &:focus,
    &:hover
      color: var(--color-grey-light)

  .footer-heading
    color: var(--color-grey)
    display: block
    margin-bottom: 1em
    text-transform: uppercase
    +from($desktops)
      margin-bottom: 0.25em
      min-height: calc(#{var(--font-size-small)} * (#{var(--line-height-base)} * 2))
