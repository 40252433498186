$phones: 480px
$tablets: 768px
$desktops: 1200px
$width-min: calc(#{$phones} - 1px)
$width-min: calc(#{$desktops} - 1px)
$content-margin-phones: 32px
$content-margin-tablets: 10vw
$content-margin-desktops: 100px
$spacing-list-inline: 5px
$grid-gutter-width: $content-margin-phones

@import ./tools/_media-queries
@import ./tools/_shadows
@import ./tools/_animations

@import ./base/_document
@import ./base/_figure
@import ./base/_links
@import ./base/_text

@import ./components/_block-banner
@import ./components/_block-button
@import ./components/_block-floatingteaser
@import ./components/_block-gallery
@import ./components/_block-heading
@import ./components/_block-imageteaser
@import ./components/_block-job
@import ./components/_block-logobox
@import ./components/_block-publication
@import ./components/_block-shiftedteaser
@import ./components/_block-slider
@import ./components/_blocks-image
@import ./components/_container-logobox
@import ./components/_cookie-banner
@import ./components/_copyright
@import ./components/_footer
@import ./components/_layouts
// @import ./components/_main
@import ./components/_list-inline
@import ./components/_logo
@import ./components/_menu
@import ./components/_menu-cta
@import ./components/_network
@import ./components/_section-articles
@import ./components/_text-right
@import ./components/_toggle-menu
@import ./components/_home
@import ./components/_cta
@import ./components/_list
