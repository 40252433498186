.block-imageteaser
  +shadow-image
  color: var(--color-dark)
  display: flex
  flex-direction: column

  +from($tablets)
    margin-top: 60px

  &.gray
    background-color: var(--color-grey-light)
  &.white
    background-color: #fff

  &_image
    height: 200px
    display: flex
    justify-content: flex-end
    align-items: flex-end
    background-size: cover
    background-position: center center
    border-radius: 5px
    z-index: +1
    position: relative
    width: 100%

    +from($tablets)
      height: 30vh
    +from($desktops)
      height: 40vh

  &_textblock
    padding: 40px

    ul
      list-style-type: "×"
    li
      padding-left: 16px

  &_title
    margin-bottom: 10px

// Workaround to remove margin-top from the first element
.col-md-6:first-of-type .block-imageteaser:first-of-type
  +from($tablets)
    margin-top: 0
