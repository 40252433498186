a
  text-decoration: none
  font-style: italic
  color: var(--color-blue)
  font-weight: 300
  transition: color 200ms ease, border 200ms ease
  border-bottom: 1px solid transparent
  padding-bottom: 3px
  &:hover
    border-bottom: 1px solid currentColor
    color: var(--color-blue)

article,
p
  a
    border-bottom: 1px solid currentColor
