.h1,
h1
  margin: 0 0 1rem
  text-transform: uppercase
  @extend #{'.ff-display', '.fs-h1', '.fw-bold', '.lh-h1', '.ls-2'}

.h2,
h2
  margin: 0 0 1rem
  text-transform: uppercase
  @extend #{'.ff-display', '.fs-h2', '.fw-bold', '.lh-h2', '.ls-2'}

.h3,
h3
  margin: 0 0 1rem
  text-transform: uppercase
  @extend #{'.ff-display', '.fs-h3', '.fw-bold', '.lh-h3', '.ls-2'}

.h4,
h4
  margin: 0 0 1rem
  text-transform: uppercase
  @extend #{'.ff-display', '.fs-h4', '.fw-bold', '.lh-h4', '.ls-2'}

.h5,
h5
  margin: 0 0 1rem
  @extend #{'.ff-display', '.fs-h5', '.fw-normal', '.lh-h5', '.ls-1_25'}

.h6,
h6
  margin: 0 0 1rem
  @extend #{'.ff-display', '.fs-h6', '.fw-normal', '.lh-h6', '.ls-1'}
