small
  font-size: var(--font-size-small)

strong,
b
  font-weight: bold

p, article
  font-weight: lighter

p:not(:last-child)
  margin: 0 0 1em

code
  background-color: var(--color-light)
  display: block
  line-height: 1.6rem
  padding: 20px

em
  font-style: italic
