\:root
  --color-black: #000
  --color-blue-dark: #0a2842
  --color-blue: #4dc1e9
  --color-blueberry: var(--color-blue)
  --color-charcoal: #0e1214
  --color-dark: #1c2429
  --color-green: #a8cb5f
  --color-grey-blue-over: #30373d
  --color-grey-blue: #3d454d
  --color-grey-dark: #7b7f80
  --color-grey-light: #ededed
  --color-grey-medium: #5f6d76
  --color-grey: #8fa5b2
  --color-light: #f8fafb
  --color-link: var(--color-blue)
  --color-ocean: var(--color-blue-dark)
  --color-text: var(--color-dark)
  --color-white: #fff

.color-blueberry
  color: var(--color-blueberry)

.color-green
  color: var(--color-green)

.color-ocean
  color: var(--color-ocean)

.color-white
  color: var(--color-white)
