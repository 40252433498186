.block-shiftedteaser
  box-shadow: 0 4px 6px #cfd3d5
  justify-content: center
  margin-top: -40px
  padding: 120px 40px
  margin-right: calc(calc(var(--bs-gutter-x) * 0.75) * -1)
  margin-left: calc(calc(var(--bs-gutter-x) * 0.75) * -1)

  &.blue
    background-color: var(--color-blue)

  &.light_gray
    background-color: var(--color-grey-light)

  &.dark_blue
    background-color: var(--color-blue-dark)
    color: var(--color-white)

  &.dark_gray
    background-color: var(--color-grey-dark)

  &.green
    background-color: var(--color-green)

  &.white
    background-color: var(--color-white)

  &:nth-of-type(odd)
    transform: skewY(-3deg)

    .block-shiftedteaser_picture img,
    .block-shiftedteaser_description
      transform: skewY(3deg)

  &:nth-of-type(even)
    transform: skewY(3deg)

    .block-shiftedteaser_picture img,
    .block-shiftedteaser_description
      transform: skewY(-3deg)

  +from($tablets)
    display: flex
    flex-direction: row

  &.right
    flex-direction: row-reverse

  &_title
    margin-bottom: 20px

  &_picture img
    width: 100%
    height: 100%
    +from($tablets)
      max-width: 30vw
      object-fit: contain
    +from($desktops)
      max-width: 20vw

  &_description
    line-height: 1.33
    +from($tablets)
      margin: 0 20px
      max-width: 50vw

  &.left &_description,
  &.right &_description
    +from($desktops)
      margin: 0 40px

  &_text
    font-weight: lighter

    +from($tablets)
      font-size: 2rem

.block-shiftedteaser_text li
  margin-bottom: 10px

  +from($desktops)
    margin-bottom: 0
