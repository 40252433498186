.block-publication
  +shadow-image
  display: flex
  font-weight: lighter
  flex-direction: column

  +from($tablets)
    margin-top: 60px

  &.gray
    background-color: var(--color-grey)
    color: var(--color-white)

  &.white
    background-color: var(--color-white)
    color: var(--color-grey-dark)

  &_content
    padding: 40px

  &_title
    font-size: 1.4rem
    font-weight: 400
    margin-bottom: 10px

  &_link
    +shadow-button
    align-items: center
    align-self: center
    background: var(--color-grey-dark)
    border-bottom: 0
    border-radius: 2px
    bottom: -20px
    color: var(--color-light)
    display: flex
    font-family: var(--font-family-display)
    font-style: normal
    font-weight: bold
    height: 60px
    padding: 0 60px
    letter-spacing: 0.1rem
    position: relative
    text-transform: uppercase
    transition: background 300ms linear, box-shadow 200ms linear

    &:hover
      +shadow-button-hover
      border-bottom: 0
      background: var(--color-blue)
      color: var(--color-light)

// Workaround to remove margin-top from the first element
.col-md-6:first-of-type .block-publication:first-of-type
  +from($tablets)
    margin-top: 0
