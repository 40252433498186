\:root
  --hero-offset: 120px

.hero
  width: 100%

  &.is-fullheight
    height: 100vh

  &:not(.is-fullheight) .container
    padding-bottom: 15vh
    padding-top: 25vh

  &-background
    height: 100%
    position: absolute
    width: 100%
    z-index: -1

    figure,
    img
      width: 100%
      height: 100%

    img
      object-fit: cover
      object-position: center

    &:before
      background-color: rgba(248, 250, 251, 0.75)
      content: ""
      height: inherit
      position: absolute
      width: inherit

  [class^="fs-"],
  [class*=" fs-"]
    @media (max-width: 991px)
      font-size: var(--font-size-h4)

  .container
    display: flex
    flex-direction: column
    height: inherit
    justify-content: center

  .content
    @media (min-width: 1400px)
      padding-left: calc(var(--hero-offset) + 1rem)
      position: relative
      top: 5vh

  .title
    margin: 0
    padding-top: calc(var(--hero-offset) * 0.25)

    svg
      display: inline-block
      height: calc(var(--hero-offset) / 2.5)
      vertical-align: middle
      width: calc(var(--hero-offset) / 2.5)

      @media (min-width: 1400px)
        height: var(--hero-offset)
        left: 0
        position: absolute
        top: 0
        width: var(--hero-offset)

.heroes-sidekick
  height: 25vh
