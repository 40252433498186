.block-button
  a
    background-color: var(--color-grey-dark)
    color: var(--color-light)
    display: inline-block
    font-family: var(--font-family-display)
    font-size: 24px
    font-style: normal
    font-weight: 700
    letter-spacing: 1px
    line-height: 32px
    padding: 16px 50px
    text-transform: uppercase

    &:hover
      background-color: var(--color-light)
      color: var(--color-grey-dark)
