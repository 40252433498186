.stretched-link
  border: 0
  padding: 0

  @at-root .block
    position: relative

  &:focus,
  &:hover
    border: 0

  &:after
    background-color: rgba(0, 0, 0, 0)
    bottom: 0
    content: ""
    left: 0
    pointer-events: auto
    position: absolute
    right: 0
    top: 0
    z-index: 1
