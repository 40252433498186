#menu
  font-weight: lighter
  max-width: 90vw
  +until(1399px)
    align-items: flex-start
    background-color: var(--color-charcoal)
    display: flex
    font-size: var(--font-size-base)
    height: 100%
    left: 0
    margin: 0
    padding-top: 100px
    position: fixed
    top: 0
    transform: translateX(-200vh)
    transition: transform linear 0.2s
    width: 100%
    z-index: 1
    &.toggled
      transform: translateX(0)
      transition: transform linear 0.1s
    a
      color: white
      font-weight: lighter
    .footer-heading
      color: var(--color-grey)
      display: block
      margin-bottom: 1em
      text-transform: uppercase
      +until($phones)
        margin-bottom: 0
    .network li
      display: inline-block
      margin-left: 1em
      width: auto

      &:first-of-type
        margin-left: 0
    .row
      margin: 0
      padding: 0
      width: 100%
    ul:first-child
      font-size: var(--font-size-h4)
      margin-bottom: 2em
      +until($phones)
        margin-bottom: 1em
  +from(1400px)
    margin-top: 50px

  .list-inline li:not(:last-child)
    margin-right: calc(5vw - calc(#{$spacing-list-inline} * 2))

  .list-inline a:not(.menu-cta)
    border-bottom: 0
    color: inherit
    font-family: var(--font-family-display)
    font-style: normal
    letter-spacing: 0.25ch
    text-transform: uppercase

    &:focus,
    &:hover
      font-weight: bold

    svg
      transform: scale(125%)

#menu-desktop
  +until(1399px)
    display: none
  +from(1400px)
    display: flex

#menu-mobile
  +until(1399px)
    display: block
  +from(1400px)
    display: none
