.ls-0_5
  letter-spacing: 0.5px

.ls-1
  letter-spacing: 1px

.ls-1_25
  letter-spacing: 1.25px

.ls-2
  letter-spacing: 2px
