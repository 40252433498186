\:root
  --line-height-base: 2
  --line-height-h1: calc(var(--line-height-base) / 2)
  --line-height-h2: var(--line-height-h1)
  --line-height-h3: calc(var(--line-height-base) / 1.667)
  --line-height-h4: var(--line-height-h3)
  --line-height-h5: var(--line-height-h3)
  --line-height-h6: var(--line-height-h3)
  --line-height-large: var(--line-height-h3)
  --line-height-small: calc(var(--line-height-base) / 1.333)

.lh
  &-base
    line-height: var(--line-height-base)

  &-h1
    line-height: var(--line-height-h1)

  &-h2
    line-height: var(--line-height-h2)

  &-h3
    line-height: var(--line-height-h3)

  &-h4
    line-height: var(--line-height-h4)

  &-h5
    line-height: var(--line-height-h5)

  &-h6
    line-height: var(--line-height-h6)

  &-large
    line-height: var(--line-height-large)

  &-small
    line-height: var(--line-height-small)
