// TOC:
// - BACKGROUND CLASSES
// - BORDER CLASSES
// - BOX-SHADOW CLASSES
// - COLOR CLASSES
// - FILL CLASSES
// - OTHER
// - CONDITIONAL AND NOT CLASS DEPENDENT STYLING
// - COOKIE MODAL 'SAFETY BACKUP'

body
  transition: background-color 0.25s, color 0.25s, box-shadow 0.25s, fill 0.25s

.is-darkmode
  background-color: var(--color-dark)
  color: var(--color-white)

  // ==================
  // BACKGROUND CLASSES
  // ==================
  .has-background
    &-dark-darkmode
      background-color: var(--color-dark) !important

    &-charcoal-darkmode
      background-color: var(--color-charcoal) !important

    &-grey-blue-darkmode
      background-color: var(--color-grey-blue) !important

    &-white-darkmode
      background-color: var(--color-white) !important

  // ==================
  // BORDER CLASSES
  // ==================
  .has-border
    &-charcoal-darkmode
      border-color: var(--color-charcoal) !important

    &-darkmode--b-1
      border-bottom: 1px solid transparent

    &-grey-darkmode
      border-color: var(--color-grey) !important

    &-white-darkmode
      border-color: var(--color-white) !important

  // ==================
  // BOX-SHADOW CLASSES
  // ==================
  .has-shadow
    &-charcoal-darkmode
      &--0-0-10
        box-shadow: 0 0 10px var(--color-charcoal) !important

      &--0-4-6
        box-shadow: 0 4px 6px var(--color-charcoal) !important

    &-none-darkmode
      box-shadow: none !important

  // ==================
  // COLOR CLASSES
  // ==================
  .has-text
    &-charcoal-darkmode
      color: var(--color-charcoal) !important

    &-dark-darkmode
      color: var(--color-dark) !important

    &-grey-darkmode
      color: var(--color-grey) !important

    &-white-darkmode
      color: var(--color-white) !important

  // ==================
  // FILL CLASSES
  // ==================
  .has-fill-white-darkmode,
  .has-fill-white-darkmode *
    fill: var(--color-white) !important

  // ==================
  // OTHER
  // ==================
  #join-widget
    *
      background-color: inherit !important
      border-color: inherit !important
      color: inherit !important

    path
      fill: inherit !important

    [data-testid="JobCard"]
      box-shadow: var(--color-charcoal) 0 2px 4px 0, var(--color-charcoal) 0 4px 12px 0 !important

    [data-testid="JobCard"] img
      box-shadow: none !important

  .block-imageteaser
    &.gray
      background-color: var(--color-charcoal)
      color: var(--color-white)

    &.white
      background-color: var(--color-grey-dark)
      color: var(--color-white)

  .block-publication
    &.gray
      background-color: var(--color-charcoal)
      color: var(--color-white)

    &.white
      background-color: var(--color-grey-dark)
      color: var(--color-white)

  .block-shiftedteaser
    &.blue
      background-color: var(--color-grey-dark)
      color: var(--color-white)

    &.light_gray
      background-color: var(--color-grey-dark)
      color: var(--color-white)

    &.dark_blue
      background-color: var(--color-blue-dark)
      color: var(--color-white)

    &.dark_gray
      background-color: var(--color-grey-medium)
      color: var(--color-white)

    &.green
      background-color: var(--color-grey-medium)
      color: var(--color-white)

    &.white
      background-color: var(--color-charcoal)
      color: var(--color-white)

  .cookie-modal__option.checked .cookie-modal__label:before
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='15' height='14' viewBox='0 0 15 14'%3E%3Cpath fill='%23fff' fill-rule='nonzero' stroke='none' stroke-width='1' d='M13.1966364,0.404511181 C13.5255158,-0.0391742814 14.1518034,-0.132242989 14.5954888,0.196636405 C15.0050446,0.500217383 15.1158501,1.05721124 14.8718011,1.49007689 L14.8033636,1.59548882 L5.82879683,13.6993423 L5.2612327,13.9170378 L5.06891712,13.9837379 C4.81805851,14.0639262 4.72428854,14.0565536 4.62320775,13.9861615 L4.55293337,13.9301665 C4.54043908,13.9193677 4.52740352,13.9078582 4.51360115,13.8956717 L4.37057828,13.7900231 L4.31059284,13.7347804 L4.24596838,13.6695647 L4.10200083,13.5075757 L3.9330407,13.3017138 L3.60664507,12.8832941 L3.1619859,12.2935061 L2.14864925,10.9131953 L1.13746992,9.51106047 L0.184024713,8.17438013 C-0.135243802,7.72372942 -0.0287374921,7.09958657 0.421913215,6.78031805 C0.837898484,6.48560866 1.40171138,6.55368964 1.7371037,6.92035647 L1.8380626,7.04937082 L3.10285636,8.81909292 L4.05235052,10.1300194 L4.79193854,11.1336606 L5.019,11.436 L13.1966364,0.404511181 Z'/%3E%3C/svg%3E%0A")

  .cover-image
    opacity: 0.25

  .is-italic-darkmode
    font-style: italic

  a,
  a:focus,
  a:hover
    color: var(--color-white)

  // ==================
  // CONDITIONAL AND NOT
  // CLASS DEPENDENT STYLING
  // ==================
  #toggle-menu
    &.toggled svg
      fill: var(--color-dark)

  .hero-background:before
    background-color: rgba(28, 36, 41, 0.75)

  .shell-claims .visible-claim:after
    background-color: var(--color-white)

  // TODO
  .block-shiftedteaser_link:focus,
  .block-shiftedteaser_link:hover,
  .cta:focus,
  .cta:hover,
  .stretched-link:hover + .block .block-shiftedteaser_description .background-hover-blue,
  .stretched-link:hover + .block .block-shiftedteaser_description .block-shiftedteaser_link,
  .stretched-link:hover + .block .block-shiftedteaser_description .cta,
  .stretched-link:hover ~ .background-hover-blue,
  .stretched-link:hover ~ .block-shiftedteaser_link,
  .stretched-link:hover ~ .cta
    background-color: var(--color-charcoal)

  .background-hover-blue,
  .block-job_apply:focus,
  .block-job_apply:hover,
  .block-shiftedteaser_link,
  .cta,
  .menu-cta:focus,
  .menu-cta:hover
    background-color: var(--color-white)

  .block-logobox:focus,
  .block-logobox:hover
    background-color: var(--color-grey-blue-over)

  .menu-cta:focus,
  .menu-cta:hover
    border-color: var(--color-white)

  .block-heading.blue h2,
  .block-heading.blue h3,
  .block-heading.blue h4,
  .block-heading.grey h2,
  .block-heading.grey h3,
  .block-heading.grey h4,
  .color-blueberry,
  .stretched-link:hover + .block .block-shiftedteaser_description .background-hover-blue,
  .stretched-link:hover + .block .block-shiftedteaser_description .block-shiftedteaser_link,
  .stretched-link:hover + .block .block-shiftedteaser_description .cta,
  .stretched-link:hover ~ .background-hover-blue,
  .stretched-link:hover ~ .block-shiftedteaser_link,
  .stretched-link:hover ~ .cta,
  .stretched-link:hover ~ .text-hover-blue
    color: var(--color-white)

  .background-hover-blue,
  .block-job_apply:focus,
  .block-job_apply:hover,
  .block-shiftedteaser_link,
  .cta,
  .menu-cta:focus,
  .menu-cta:hover
    color: var(--color-dark)

  // ==================
  // COOKIE MODAL
  // 'SAFETY BACKUP'
  // ==================
  .cookie-modal
    @extend #{'.has-background-grey-blue-darkmode', '.has-shadow-none-darkmode', '.has-text-white-darkmode'}

    &__option
      &.disabled .cookie-modal__label
        @extend #{'.has-text-grey-darkmode'}

        &:before
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='15' height='14' viewBox='0 0 15 14'%3E%3Cpath fill='%238fa5b2' fill-rule='nonzero' stroke='none' stroke-width='1' d='M13.1966364,0.404511181 C13.5255158,-0.0391742814 14.1518034,-0.132242989 14.5954888,0.196636405 C15.0050446,0.500217383 15.1158501,1.05721124 14.8718011,1.49007689 L14.8033636,1.59548882 L5.82879683,13.6993423 L5.2612327,13.9170378 L5.06891712,13.9837379 C4.81805851,14.0639262 4.72428854,14.0565536 4.62320775,13.9861615 L4.55293337,13.9301665 C4.54043908,13.9193677 4.52740352,13.9078582 4.51360115,13.8956717 L4.37057828,13.7900231 L4.31059284,13.7347804 L4.24596838,13.6695647 L4.10200083,13.5075757 L3.9330407,13.3017138 L3.60664507,12.8832941 L3.1619859,12.2935061 L2.14864925,10.9131953 L1.13746992,9.51106047 L0.184024713,8.17438013 C-0.135243802,7.72372942 -0.0287374921,7.09958657 0.421913215,6.78031805 C0.837898484,6.48560866 1.40171138,6.55368964 1.7371037,6.92035647 L1.8380626,7.04937082 L3.10285636,8.81909292 L4.05235052,10.1300194 L4.79193854,11.1336606 L5.019,11.436 L13.1966364,0.404511181 Z'/%3E%3C/svg%3E%0A")
          @extend #{'.has-border-grey-darkmode'}

      &:not(.disabled) .cookie-modal__label
        @extend #{'.has-text-white-darkmode'}

        &:before
          @extend #{'.has-border-white-darkmode'}

    &__text a
      @extend #{'.has-border-darkmode--b-1', '.has-border-white-darkmode', '.has-text-white-darkmode'}

    #cookie-accept
      @extend #{'.has-background-white-darkmode', '.has-border-white-darkmode ', '.has-text-dark-darkmode'}

    #cookie-deny
      @extend #{'.has-background-dark-darkmode', '.has-border-white-darkmode', '.has-text-white-darkmode'}

  .cover-background:before
    background-color: rgba(61, 69, 77, 0.75)
