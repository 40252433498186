.container-logobox
  .content
    text-align: center

  .block-logobox
    +from($desktops)
      align-items: center
      display: flex
      flex-direction: column
      justify-content: center

  .row:nth-child(even)
    @media (min-width: 992px)
      transform: translateX(-#{$grid-gutter-width * 2})

  .row:nth-child(odd)
    @media (min-width: 992px)
      position: relative
      transform: translateX(#{$grid-gutter-width * 2})

  .row > *
    margin-top: 1em
