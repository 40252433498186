.block-slider

  +from($tablets)
    display: flex
    flex-direction: row-reverse
    gap: 3rem

  &_indicator
    align-items: center
    align-self: flex-end
    display: flex
    flex-shrink: 0
    font-size: 2rem
    justify-content: center
    text-align: center

    +from($tablets)
      display: initial
      width: 90px

    .spacer
      border-bottom: 2px solid var(--color-text)
      margin: 20px 30px
      max-width: 80px
      opacity: .7
      width: 100%

      +from($tablets)
        margin: 20px 0
        max-width: 100%

    .previous, .next
      cursor: pointer
      font-weight: bold
      opacity: .7

      &[aria-disabled="true"]
        cursor: not-allowed

      &[aria-disabled="false"]
        opacity: 1

  img
    display: block
    width: 100%
