.cookie-modal
  background-color: white
  bottom: 10%
  box-shadow: 0 0 10px var(--color-grey-medium)
  margin: 0 10px
  padding: 42px
  position: fixed
  z-index: 2

  +from($tablets)
    bottom: 30%
    max-width: 682px
    right: 100px

  &__buttons
    margin-top: -80px
    position: absolute
    @extend .block-button

    +from($tablets)
      margin-top: 20px

    a
      background-color: white
      border: 6px solid var(--color-dark)
      color: var(--color-dark)
      margin-bottom: 20px
      margin-right: 10px
      padding: 10px 30px

      &:hover
        color: var(--color-dark)

      &.primary
        background-color: var(--color-dark)
        color: white

        &:hover
          color: white

      &.hide
        display: none

  &__check
    display: none

  &__checkbox
    opacity: 0
    position: absolute

  &--hidden
    display: none

  &__label
    cursor: pointer
    font-weight: 300
    line-height: 25px
    position: relative

    &:before
      background-position: center
      background-repeat: no-repeat
      background-size: calc(100% - 5px)
      border: 2px solid #0A2842
      content: ""
      display: inline-block
      height: 25px
      margin-right: 8px
      vertical-align: middle
      width: 25px

  &__option
    &.checked .cookie-modal__label:before
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='15' height='14' viewBox='0 0 15 14'%3E%3Cpath fill='%234DC1E9' fill-rule='nonzero' stroke='none' stroke-width='1' d='M13.1966364,0.404511181 C13.5255158,-0.0391742814 14.1518034,-0.132242989 14.5954888,0.196636405 C15.0050446,0.500217383 15.1158501,1.05721124 14.8718011,1.49007689 L14.8033636,1.59548882 L5.82879683,13.6993423 L5.2612327,13.9170378 L5.06891712,13.9837379 C4.81805851,14.0639262 4.72428854,14.0565536 4.62320775,13.9861615 L4.55293337,13.9301665 C4.54043908,13.9193677 4.52740352,13.9078582 4.51360115,13.8956717 L4.37057828,13.7900231 L4.31059284,13.7347804 L4.24596838,13.6695647 L4.10200083,13.5075757 L3.9330407,13.3017138 L3.60664507,12.8832941 L3.1619859,12.2935061 L2.14864925,10.9131953 L1.13746992,9.51106047 L0.184024713,8.17438013 C-0.135243802,7.72372942 -0.0287374921,7.09958657 0.421913215,6.78031805 C0.837898484,6.48560866 1.40171138,6.55368964 1.7371037,6.92035647 L1.8380626,7.04937082 L3.10285636,8.81909292 L4.05235052,10.1300194 L4.79193854,11.1336606 L5.019,11.436 L13.1966364,0.404511181 Z'/%3E%3C/svg%3E%0A")
      border-color: #4DC1E9

    &.disabled
      color: var(--color-grey-dark)

      .cookie-modal__label:before
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='15' height='14' viewBox='0 0 15 14'%3E%3Cpath fill='%237b7f80' fill-rule='nonzero' stroke='none' stroke-width='1' d='M13.1966364,0.404511181 C13.5255158,-0.0391742814 14.1518034,-0.132242989 14.5954888,0.196636405 C15.0050446,0.500217383 15.1158501,1.05721124 14.8718011,1.49007689 L14.8033636,1.59548882 L5.82879683,13.6993423 L5.2612327,13.9170378 L5.06891712,13.9837379 C4.81805851,14.0639262 4.72428854,14.0565536 4.62320775,13.9861615 L4.55293337,13.9301665 C4.54043908,13.9193677 4.52740352,13.9078582 4.51360115,13.8956717 L4.37057828,13.7900231 L4.31059284,13.7347804 L4.24596838,13.6695647 L4.10200083,13.5075757 L3.9330407,13.3017138 L3.60664507,12.8832941 L3.1619859,12.2935061 L2.14864925,10.9131953 L1.13746992,9.51106047 L0.184024713,8.17438013 C-0.135243802,7.72372942 -0.0287374921,7.09958657 0.421913215,6.78031805 C0.837898484,6.48560866 1.40171138,6.55368964 1.7371037,6.92035647 L1.8380626,7.04937082 L3.10285636,8.81909292 L4.05235052,10.1300194 L4.79193854,11.1336606 L5.019,11.436 L13.1966364,0.404511181 Z'/%3E%3C/svg%3E%0A")
        border-color: var(--color-grey-dark)

    + .cookie-modal__option
      margin-left: 1rem

  &__options
    margin-bottom: 100px

    +from($tablets)
      margin-bottom: 0

  &__text
    // line-height: var(--line-height-small)

    a
      border-bottom-width: 0
      color: var(--color-dark)
      font-weight: bold

      &:hover
        border-bottom-width: 1px

  &__title
    // line-height: var(--line-height-small)
    @extend .h4
