.cta
  background-color: var(--color-dark)
  border: 0
  color: var(--color-light)
  cursor: pointer
  display: inline-block
  font-family: var(--font-family-display)
  font-weight: bold
  font-size: 1.414em
  font-style: normal
  letter-spacing: 0.1rem
  padding: calc(#{$grid-gutter-width} / 2) $grid-gutter-width
  text-align: center
  text-transform: uppercase
  transition: background-color 300ms

  &:hover
    background: var(--color-blue)
    border: 0
    color: var(--color-light)
    transition: background-color 300ms

.block-shiftedteaser_link
  margin-top: 2em
  @extend .cta
