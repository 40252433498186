#menu-mobile
  padding: 0 24px
  text-transform: uppercase
  width: 100%

  .list-unstyled a:not(.menu-cta)
    border: 0
    font-style: normal

  .menu-cta
    display: block

  .menu-icon
    display: inline-block
    height: auto
    line-height: 0
    vertical-align: middle
    width: 24px

    svg
      height: inherit
      width: inherit

  .menu-label
    margin-left: 1em

  li:not(.active) svg *
    fill: var(--color-white)

  a
    font-weight: normal

  p
    margin: 0

  p > a
    font-family: var(--font-family-base)
    font-style: italic !important
    text-transform: none
