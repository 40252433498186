@font-face
  font-family: "Lelo"
  font-style: normal
  font-weight: bold
  src: url("../fonts/lelo/Lelo-Bold.ttf") format("truetype")
  font-display: swap

@font-face
  font-family: "Lelo"
  font-style: normal
  font-weight: normal
  src: url("../fonts/lelo/Lelo-Regular.ttf") format("truetype")
  font-display: swap

@font-face
  font-family: "Source Code Pro"
  font-style: normal
  font-weight: bold
  src: url("../fonts/source-code-pro/SourceCodePro-Bold.ttf") format("truetype")
  font-display: swap

@font-face
  font-family: "Source Code Pro"
  font-style: normal
  font-weight: normal
  src: url("../fonts/source-code-pro/SourceCodePro-Medium.ttf") format("truetype")
  font-display: swap

@font-face
  font-family: "Source Sans Pro"
  font-style: normal
  font-weight: 300
  src: url("../fonts/source-sans-pro/source-sans-pro-v21-latin-300.eot")
  src: local(""), url("../fonts/source-sans-pro/source-sans-pro-v21-latin-300.eot?#iefix") format("embedded-opentype"), url("../fonts/source-sans-pro/source-sans-pro-v21-latin-300.woff2") format("woff2"), url("../fonts/source-sans-pro/source-sans-pro-v21-latin-300.woff") format("woff"), url("../fonts/source-sans-pro/source-sans-pro-v21-latin-300.ttf") format("truetype"), url("../fonts/source-sans-pro/source-sans-pro-v21-latin-300.svg#SourceSansPro") format("svg")
  font-display: swap

@font-face
  font-family: "Source Sans Pro"
  font-style: italic
  font-weight: 300
  src: url("../fonts/source-sans-pro/source-sans-pro-v21-latin-300italic.eot")
  src: local(""), url("../fonts/source-sans-pro/source-sans-pro-v21-latin-300italic.eot?#iefix") format("embedded-opentype"), url("../fonts/source-sans-pro/source-sans-pro-v21-latin-300italic.woff2") format("woff2"), url("../fonts/source-sans-pro/source-sans-pro-v21-latin-300italic.woff") format("woff"), url("../fonts/source-sans-pro/source-sans-pro-v21-latin-300italic.ttf") format("truetype"), url("../fonts/source-sans-pro/source-sans-pro-v21-latin-300italic.svg#SourceSansPro") format("svg")
  font-display: swap

@font-face
  font-family: "Source Sans Pro"
  font-style: normal
  font-weight: 400
  src: url("../fonts/source-sans-pro/source-sans-pro-v21-latin-regular.eot")
  src: local(""), url("../fonts/source-sans-pro/source-sans-pro-v21-latin-regular.eot?#iefix") format("embedded-opentype"), url("../fonts/source-sans-pro/source-sans-pro-v21-latin-regular.woff2") format("woff2"), url("../fonts/source-sans-pro/source-sans-pro-v21-latin-regular.woff") format("woff"), url("../fonts/source-sans-pro/source-sans-pro-v21-latin-regular.ttf") format("truetype"), url("../fonts/source-sans-pro/source-sans-pro-v21-latin-regular.svg#SourceSansPro") format("svg")
  font-display: swap

@font-face
  font-family: "Source Sans Pro"
  font-style: italic
  font-weight: 400
  src: url("../fonts/source-sans-pro/source-sans-pro-v21-latin-italic.eot")
  src: local(""), url("../fonts/source-sans-pro/source-sans-pro-v21-latin-italic.eot?#iefix") format("embedded-opentype"), url("../fonts/source-sans-pro/source-sans-pro-v21-latin-italic.woff2") format("woff2"), url("../fonts/source-sans-pro/source-sans-pro-v21-latin-italic.woff") format("woff"), url("../fonts/source-sans-pro/source-sans-pro-v21-latin-italic.ttf") format("truetype"), url("../fonts/source-sans-pro/source-sans-pro-v21-latin-italic.svg#SourceSansPro") format("svg")
  font-display: swap

@font-face
  font-family: "Source Sans Pro"
  font-style: normal
  font-weight: 700
  src: url("../fonts/source-sans-pro/source-sans-pro-v21-latin-700.eot")
  src: local(""), url("../fonts/source-sans-pro/source-sans-pro-v21-latin-700.eot?#iefix") format("embedded-opentype"), url("../fonts/source-sans-pro/source-sans-pro-v21-latin-700.woff2") format("woff2"), url("../fonts/source-sans-pro/source-sans-pro-v21-latin-700.woff") format("woff"), url("../fonts/source-sans-pro/source-sans-pro-v21-latin-700.ttf") format("truetype"), url("../fonts/source-sans-pro/source-sans-pro-v21-latin-700.svg#SourceSansPro") format("svg")
  font-display: swap

@font-face
  font-family: "Source Sans Pro"
  font-style: italic
  font-weight: 700
  src: url("../fonts/source-sans-pro/source-sans-pro-v21-latin-700italic.eot")
  src: local(""), url("../fonts/source-sans-pro/source-sans-pro-v21-latin-700italic.eot?#iefix") format("embedded-opentype"), url("../fonts/source-sans-pro/source-sans-pro-v21-latin-700italic.woff2") format("woff2"), url("../fonts/source-sans-pro/source-sans-pro-v21-latin-700italic.woff") format("woff"), url("../fonts/source-sans-pro/source-sans-pro-v21-latin-700italic.ttf") format("truetype"), url("../fonts/source-sans-pro/source-sans-pro-v21-latin-700italic.svg#SourceSansPro") format("svg")
  font-display: swap

