.menu-cta
  border: 4px solid var(--color-black)
  color: inherit
  font-family: var(--font-family-display)
  font-size: 18px
  font-style: normal
  font-weight: 700
  letter-spacing: 0.25ch
  padding: 0.5rem 1.25rem
  text-align: center
  text-transform: uppercase

  @at-root #menu-mobile &
    border-color: var(--color-white)

  &:focus,
  &:hover
    background-color: var(--color-black)
    border-color: var(--color-black)
    border-bottom-width: 4px
    color: var(--color-white)
