#toggle-menu
  background-color: transparent
  border: 0
  margin-left: $grid-gutter-width
  margin-top: $grid-gutter-width
  position: absolute
  z-index: 2

  +from(1400px)
    display: none

  &.toggled
    position: fixed

    span
      color: white

    svg
      fill: white
      transform: rotate(-90deg)
      transition: transform 200ms

  span,
  svg
    display: inline-block
    vertical-align: middle

  span
    font-family: var(--font-family-display)
    font-size: var(--font-size-h4)
    margin-left: 1em
    text-transform: uppercase

  svg
    fill: var(--color-text)
    transform: rotate(0)
    transition: transform 200ms
