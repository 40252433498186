.container
  &.spacing-top
    margin-top: 50px

    +from($desktops)
      margin-top: 100px

  &.spacing-bottom
    margin-bottom: 50px

    +from($desktops)
      margin-bottom: 100px

  &.spacing-top-bottom
    margin-bottom: var(--bs-gutter-y)
    margin-top: var(--bs-gutter-y)

    +from($desktops)
      margin-bottom: 100px
      margin-top: 100px

  &.narrow
    +from($desktops)
      max-width: 960px
  // = bs-grid container-lg

  &.wide
    +from($desktops)
      max-width: 1800px

[class*='col-'] > .block:not(.block-logobox)
  margin-bottom: 40px
