.block-banner
  font-size: 1.6rem

  &_button
    color: #fff
    font-size: 1.2rem
    font-style: unset

  &_headline
    line-height: 4rem
    text-transform: unset

  &_image img
    display: block
    height: 100%
    object-fit: cover
    width: 100%

  &_content
    line-height: 2.6rem
    padding: 60px 40px

figure
  &.block-banner_image
    width: 100%
    height: 100%
