.block-floatingteaser
  +from($tablets)
    display: flex
    flex-direction: row
  +from($desktops)
    &:not(:first-of-type)
      margin-top: -150px

  &.right
    flex-direction: row-reverse

  &_title
    margin-bottom: 20px

  &_picture
    img
      +shadow-image
      width: 100%
      height: auto

      +from($tablets)
        max-width: 30vw
        object-fit: cover

      +from($desktops)
        max-width: 400px

  &_description
    line-height: 1.33

    +from($tablets)
      align-self: flex-end
      margin: 0 20px
      max-width: 40ch

  &.left &_description
    +from($desktops)
      margin: 0 40px

  &.right &_description
    +from($tablets)
      align-self: flex-end
      text-align: right

    +from($desktops)
      margin: 0 60px


