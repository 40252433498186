.network
  position: relative
  @at-root #footer &
    +until($tablets)
      display: flex
      flex-wrap: wrap
  @at-root #menu-desktop &
    padding-top: var(--font-size-base)
    position: absolute
    right: calc(#{$grid-gutter-width} / 2)

  a
    border: 0
    color: currentColor
    font-style: normal

    &:focus,
    &:hover
      color: var(--color-grey)
      text-decoration: none

      svg
        fill: currentColor
        @at-root #menu-desktop &
          fill: var(--color-text)
          transition: fill ease-in 0.3s

  li
    margin-bottom: 1em

    &:last-child
      margin-bottom: 0
    @at-root #footer &
      +until($tablets)
        flex-grow: 1
        width: 33.333%

  p,
  svg
    display: inline-block
    vertical-align: middle

  p
    margin-left: 10px
    @at-root #footer &
      @extend %sr-only
      +from($tablets)
        height: auto
        margin: 0 0 0 10px
        position: relative
        width: auto
    @at-root #menu &
      @extend %sr-only

  svg
    fill: white
    @at-root #menu-desktop &
      fill: var(--color-grey-dark)
      transform: scale(90%)
      transition: fill ease-in 0.3s
