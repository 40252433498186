figure
  margin: 0 0 1em

  &:last-child
    margin-bottom: 0

  img
    max-width: 100%

  figcaption
    background-color: var(--color-grey)
    bottom: 0
    font-size: var(--font-size-small)
    left: 0
    padding: 5px 10px
    position: absolute
