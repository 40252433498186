.img-fluid
  height: auto
  max-width: 100%

figure
  &.img-fluid
    img
      height: auto
      max-width: 100%

