\:root
  --testimonial-spacer: 1rem

.block-testimonials
  &_quote
    font-family: var(--font-family-display)
    font-size: 1.25rem
    font-weight: bold
    letter-spacing: 1.25px
    line-height: 1.25
    margin: 0

    &:after
      content: "\201C"

    &:before
      content: "\201E"

  &_slide
    background-color: var(--color-light)
    box-shadow: 0 5px 10px rgba(28, 36, 41, 0.1), 0 4px 10px rgba(28, 36, 41, 0.2)
    margin: 20px
    padding: calc(var(--testimonial-spacer) * 2)
    position: relative

    &:not(:last-of-type)
      margin-right: 60px

  &_texts
    align-items: center
    display: flex
    justify-content: space-between
    margin-top: var(--testimonial-spacer)

    p
      font-size: var(--font-size-small)
      line-height: 1.5
      margin: 0

  .block-slider_indicator
    cursor: pointer
    margin-bottom: calc(var(--testimonial-spacer) * 3)

    .disabled
      cursor: not-allowed
      opacity: 0.7

  .cta
    bottom: calc(calc(var(--testimonial-spacer) * 3) * -1)
    display: table
    left: 0
    margin: auto
    min-width: 200px
    position: absolute
    right: 0

  .glider
    padding: 0 0 calc(var(--testimonial-spacer) * 3)

.block-testimonials.block-slider
  img
    width: auto
