.fw-bold
  font-weight: 700

.fw-light
  font-weight: 300

.fw-medium
  font-weight: 500

.fw-normal
  font-weight: 400
