.block-logobox
  background-color: var(--color-grey-light)
  border-radius: 3em
  box-shadow: 0 4px 6px #CFD3D5
  display: inline-block
  margin: 0
  min-height: 100%
  padding: 1.4em
  width: auto

  +from($desktops)
    display: block
    padding: 2em
    width: 100%

  &:not(.not-empty)
    @media (max-width: 767px)
      display: none

  &:not(.not-empty):after
    @media (max-width: 991px)
      content: ""
      display: block
      padding-bottom: 100%

  a:focus,
  a:hover
    border: 0

  img
    margin: 0 auto
    max-height: 100px
    max-width: 100%
    vertical-align: middle

    +from($desktops)
      max-height: 212px

  &.with-title img
    max-height: 160px

  &_title
    font-weight: bold
    display: block
    line-height: normal
    text-align: center
