.blocks-image
  position: relative
  display: inherit
  margin-bottom: 40px
  .picture
    margin: 0

.blocks
  display: grid
  grid-template-columns: 1fr 1fr
  position: absolute
  width: 40px

  +until($tablets)
    width: 20px

  &-outer-bottom-left .blocks
    bottom: -20px
    left: -20px
    transform: rotate(270deg)

    +until($tablets)
      bottom: -10px
      left: -10px

  &-outer-bottom-right .blocks
    bottom: -20px
    right: -20px
    transform: rotate(180deg)

    +until($tablets)
      bottom: -10px
      right: -10px

  &-outer-top-left .blocks
    left: -20px
    top: -20px

    +until($tablets)
      left: -10px
      top: -10px

  &-outer-top-right .blocks
    right: 0
    top: 0
    transform: rotate(90deg)

  &-inner-bottom-left .blocks
    bottom: 0
    left: 0
    transform: rotate(270deg)

  &-inner-bottom-right .blocks
    bottom: 0
    right: 0
    transform: rotate(180deg)

  &-inner-top-left .blocks
    left: 0
    top: 0

  &-inner-top-right .blocks
    right: 0
    top: 0
    transform: rotate(90deg)

  .block
    height: 20px
    position: relative
    width: 20px
    +until($tablets)
      height: 10px
      width: 10px
    &:nth-child(1)
      background: var(--color-blue-dark)
    &:nth-child(2)
      background: var(--color-green)
    &:nth-child(3)
      background: var(--color-blue)
