.block-gallery
  columns: 3 200px
  column-gap: 20px

  +from($tablets)
    column-gap: 56px

  img
    width: 100%
    height: 100%
    object-fit: contain

  &_item
    display: block

    +from($phones)
      margin-bottom: 20px

      +from($tablets)
        margin-bottom: 56px
