.block-job
  +shadow-image
  display: flex
  flex-direction: column

  +from($tablets)
    margin-top: 60px

  &_image
    height: 200px
    display: flex
    justify-content: flex-end
    align-items: flex-end
    background-size: cover
    background-position: center center
    border-radius: 5px
    z-index: +1
    position: relative
    width: 100%

    +from($tablets)
      height: 30vh
    +from($desktops)
      height: 40vh

  &_block
    width: 40px
    height: 40px

    +from($tablets)
      width: 80px
      height: 80px

  &_textblock
    padding: 40px

  &_title
    margin-bottom: 10px

  &_description
    margin-top: 40px

    ul
      list-style-type: "×"

    li
      padding-left: 16px

  &_apply
    +shadow-button
    align-items: center
    align-self: center
    background: var(--color-dark)
    border-bottom: 0
    border-radius: 2px
    bottom: -20px
    color: var(--color-light)
    display: flex
    font-family: var(--font-family-display)
    font-style: normal
    font-weight: bold
    height: 60px
    padding: 0 60px
    letter-spacing: 0.1rem
    position: relative
    text-transform: uppercase
    transition: background 300ms linear, box-shadow 200ms linear

    &:hover
      +shadow-button-hover
      border-bottom: 0
      background: var(--color-blue)
      color: var(--color-light)

  // Light blue color
  &.light-blue
    background: var(--color-blue)

  &.light-blue &_block
    background: var(--color-blue-dark)

  // Dark blue color
  &.dark-blue
    background: var(--color-blue-dark)

  &.dark-blue &_block
    background: var(--color-blue)

  // Gray color
  &.gray
    background-color: var(--color-grey)
    color: var(--color-light)

  &.gray &_block
    background: var(--color-grey-medium)

  &.gray &_apply:hover
    background-color: var(--color-grey-medium)

// Workaround to remove margin-top from the first element
.col-md-6:first-of-type .block-job:first-of-type
  +from($tablets)
    margin-top: 0
